/*#header {
  --folder-border-thickness: 2px;
  --folder-border-style: solid;
  --folder-border-color: currentColor;

  --folder-shadow-offset-x: 2px;
  --folder-shadow-offset-y: 2px;
  --folder-shadow-blur: 5px;
  --folder-shadow-opacity: 0.25;

  --mobile-nested-item-size: -1vmin;
  --mobile-nested-folder-padding-bottom: 1rem;
  --mobile-nested-folder-padding-top: 0.5rem;
  --mobile-nested-item-spacing: 2vw;
  --mobile-nested-folder-icon-transform: rotate(45deg);
  --mobile-nested-folder-icon-size: 25px;
}*/

#header .header-nav .nested-folder.header-nav-folder-content {
  position: absolute;
  left: calc(100%);
  top: calc(-0.5em - var(--folder-border-thickness, 1px));
  padding: 0.5em 0em;
  white-space: initial;
  min-width: var(--nested-folder-min-width, 200px);
  max-width: var(--nested-folder-max-width, initial);
  width: 100%;
  box-sizing: border-box;
  visibility: hidden;
  opacity:0;
}
#header .header-nav .header-nav-item--nested-folder:hover .nested-folder,
#header .header-nav .header-nav-item--nested-folder.focus .nested-folder{
  /* display:block; */
  opacity: 1;
  visibility: visible;
}
#header  .header-nav .header-nav-item--nested-folder{
  position: relative;

}
#header .header-layout-nav-right .header-nav .header-nav-item--folder .nested-folder.header-nav-folder-content {
  left: 100%;
}
#header .header-nav .header-nav-item--folder.folder-side--flipped .nested-folder.header-nav-folder-content {
  right: 100%;
  left: initial;
}

/** Add Indicator CSS **/
/* #header  .header-nav .header-nav-item--nested-folder{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-nav-item--nested-folder:after {
  content: var(--nested-folder-indicator, '\25B6');
  position: relative;
  line-height: 0;
  font-size: var(--nested-folder-indicator-size, 0.8em);
  padding-left: 8px;
}
#header  .header-nav .folder-side--flipped .header-nav-item--nested-folder{
  flex-direction: row-reverse;
}
.folder-side--flipped .header-nav-item--nested-folder:after {
  transform: rotate(180deg);
} */


/*All Desktop Folders*/
#header .header-nav-folder-content {
  padding: 0.5em 0em;
  box-shadow: var(--folder-shadow-offset-x, 2px) 
    var(--folder-shadow-offset-y, 2px)
    var(--folder-shadow-blur, 0px)
    var(--folder-shadow-spread, 0px)
    hsla(0, 0%, 0%, var(--folder-shadow-opacity, 0%))
  ;
  border: var(--folder-border-thickness, 1px) 
    var(--folder-border-style, solid) 
    var(--folder-border-color, var(--navigationLinkColor, currentColor))
  ;
}



/*All Desktop Folder Items*/
#header .header-nav-item--folder .header-nav-folder-content .header-nav-folder-item{
  padding: 0 1em;
}

/*Mobile Folder*/
.header-menu-nav-item--accordion-folder {
  overflow: hidden; /* Ensure content doesn't overflow */
}
.header-menu-nav-item--accordion-folder.header-menu-nav-item > a {
  display: flex;
  gap: var(--mobile-nested-folder-gap, 8px);
  justify-content: var(--mobile-nested-folder-alignment, space-between);
  align-items: center;
}
/*Mobile Alignment -- RIGHT*/
.header-overlay-alignment-right .header-menu .header-menu-nav-item--accordion-folder.header-menu-nav-item > a{
  justify-content: var(--mobile-nested-folder-alignment, end);
}
.header-overlay-alignment-right .header-menu-nav-item--accordion-folder .accordion-folder-wrapper .header-menu-nav-item {
  padding-right: calc(var(--mobile-nested-folder-icon-size, 25px) + var(--mobile-nested-folder-gap, 8px) + var(--mobile-nested-item-inset, 12px));
}


/*Mobile Alignment -- CENTER*/
.header-overlay-alignment-center .header-menu .header-menu-nav-item--accordion-folder.header-menu-nav-item > a{
  justify-content: var(--mobile-nested-folder-alignment, center);
}
.header-overlay-alignment-center .header-menu-nav-item--accordion-folder .accordion-folder-wrapper .header-menu-nav-item {
  padding-right: 2vw;
  padding-left: 2vw;
}

/*Mobile Alignment -- LEFT*/
.header-overlay-alignment-left .header-menu .header-menu-nav-item--accordion-folder.header-menu-nav-item > a{
  justify-content: var(--mobile-nested-folder-alignment, space-between);
}
.header-overlay-alignment-left .header-menu-nav-item--accordion-folder .accordion-folder-wrapper .header-menu-nav-item {
  padding-left: var(--mobile-nested-item-inset, 12px);
}

.header-menu-nav-item--accordion-folder > a .icon {
  display: grid;
  place-items: center;
  transition: transform 0.3s ease;
  width: var(--mobile-nested-folder-icon-size, 25px);
  height: var(--mobile-nested-folder-icon-size, 25px);
}
.header-overlay-alignment-center .header-menu .header-menu-nav-item--accordion-folder > a .icon {
  position:absolute;
  right: 0;
}
.header-menu-nav-item--accordion-folder > a.open .icon {
  transform: var(--mobile-nested-folder-icon-transform, rotate(45deg));
}
.header-menu-nav-item--accordion-folder.header-menu-nav-item a {
  transition: background-color 0.3s ease; 
}

.header-menu-nav-item--accordion-folder .accordion-folder-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}
.header-menu-nav-item--accordion-folder .accordion-folder-wrapper{
  padding-bottom: var(--mobile-nested-folder-padding-bottom, 1rem);
  padding-top: var(--mobile-nested-folder-padding-top, 0.5rem);
}
.header-menu-nav-item--accordion-folder .accordion-folder-wrapper .header-menu-nav-item a{
  margin-top: var(--mobile-nested-item-spacing, 2vw);
  margin-bottom: var(--mobile-nested-item-spacing, 2vw);
}
.header-menu-nav-item--accordion-folder .accordion-folder-wrapper .header-menu-nav-item:first-child a{
  margin-top: 0px;
}
.header-menu-nav-item--accordion-folder .accordion-folder-wrapper .header-menu-nav-item:last-child a{
  margin-bottom: 0px;
}

/*Mobile Nested Folder Items*/
.accordion-folder-content .header-menu-nav-item a {
  font-size: calc(8.5vmin + var(--mobile-nested-item-size, -1vmin));
}
@media screen and (min-width: 576px) {
  .accordion-folder-content .header-menu-nav-item a {
    font-size: 6.6vmin;
    font-size: calc(6.6vmin + var(--mobile-nested-item-size, -1vmin));
  }
}
@media screen and (min-width: 768px) {
  .accordion-folder-content .header-menu-nav-item a {
    font-size: 6vmin;
    font-size: calc(6vmin + var(--mobile-nested-item-size, -1vmin));
  }
}

/*Active Link Styles*/
body:not(.header--menu-open) .header-nested-nav-folder-item--active > a{
  /*Level 2*/
  display:inline-block;
  background-image: linear-gradient(currentColor,currentColor);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 calc(100% - 2px);
}
:not(.header--menu-open) .header-nav-wrapper .header-nav-item--active a span{
  /*Level 3*/
  display:inline-block;
}

.header-menu-nav-item{
  display: inline-block;
}

.header-menu-nav-item--active > a[data-folder-id] .header-menu-nav-item-content span:not(.chevron),
.header-menu-nav-item--accordion-folder:has([aria-current="page"]) > a,
.header-menu-nav-item--active a {
  text-decoration: underline;
  text-underline-offset: var(--active-decoration-offset, 4px);
  text-decoration-thickness: var(--active-decoration-thickness, 1px);
}
